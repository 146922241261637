import React, { useEffect, useState } from "react";
import swal from "sweetalert";

import "../assets/css/register.css";
import "../assets/css/sweetalert.css";
import { api } from "../api";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router";

export const EditOfferPage = (props) => {
   const [cookies, setCookies] = useCookies(["profile_id"]);

   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const offerId = queryParams.get("offerId");

   const [newOffer, setNewOffer] = useState({
      profileId: cookies.profile_id,
      title: "",
      description: "",
      price: "",
      city: "",
   });

   const editOffer = (event) => {
      event.preventDefault();
      api.put(`/offers/${newOffer.id}`, newOffer, {
         headers: {
            Authorization: `Bearer ${cookies.access_token}`,
         },
      })
         .then((res) => {
            //
            swal(
               "Offre bien modifié",
               "Clickez OK pour consulter vos offres",
               "success"
            ).then((r) => {
               window.history.back();
            });
         })
         .catch((err) => {
            console.log(err);
         });
   };

   useEffect(() => {
      api.get(`offers/${offerId}`)
         .then((res) => {
            setNewOffer(res.data);
         })
         .catch((err) => {
            console.error(err);
         });
   }, []);
   return (
      <div className="register">
         <main className="main">
            <h1 className="title">Modifier un offre</h1>
            <form method="" onSubmit={editOffer}>
               <div className="fields">
                  <div>
                     <label htmlFor="title">Titre</label>
                     <input
                        type="text"
                        name="title"
                        id="title"
                        onChange={(e) =>
                           setNewOffer({ ...newOffer, title: e.target.value })
                        }
                        value={newOffer.title}
                     />
                  </div>
                  <div>
                     <label htmlFor="price">Prix</label>
                     <input
                        type="number"
                        name="price"
                        min={10}
                        id="price"
                        onChange={(e) =>
                           setNewOffer({
                              ...newOffer,
                              price: e.target.value,
                           })
                        }
                        value={newOffer.price}
                     />
                  </div>

                  <div>
                     <label htmlFor="city">Ville</label>
                     <input
                        type="city"
                        name="city"
                        id="city"
                        onChange={(e) =>
                           setNewOffer({ ...newOffer, city: e.target.value })
                        }
                        value={newOffer.city}
                     />
                  </div>

                  <div>
                     <label htmlFor="phone">Description</label>
                     <input
                        type="text"
                        name="description"
                        id="description"
                        onChange={(e) =>
                           setNewOffer({
                              ...newOffer,
                              description: e.target.value,
                           })
                        }
                        value={newOffer.description}
                     />
                  </div>
               </div>
               <div className="action">
                  <button type="submit" className="btn">
                     Modifier mon offre
                  </button>
               </div>
            </form>
         </main>
      </div>
   );
};
