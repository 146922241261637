import axios from "axios";

export const api = axios.create({
   baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
   // withCredentials: true, // Include credentials such as cookies in requests
   // headers: {
   //    "Content-Type": "application/json",
   // },
});
/*
// 
// 
//  this is executed before the request is sent
//  
api.interceptors.request.use((config) => {
   const token = localStorage.getItem("ACCESS_TOKEN");
   config.headers.Authorization = `Bearer ${token}`;
   return config;
});

// 
//   this is executed after the response is received
//  
api.interceptors.response.use(
   (response) => {
      return response;
   },
   (error) => {
      console.error(error);
      const { response } = error;

      if (response.status === 401) {
         // user is anauthorized
         localStorage.removeItem("ACCESS_TOKEN");
      } else {
         // handle other errors
         throw error;
      }
   }
);
*/
export const moroccanCities = [
   "Afourar",
   "Agadir",
   "Agdz",
   "Aghbala",
   "AgniIzimmer",
   "Agourai",
   "Ahfir",
   "AinElAouda",
   "AinTaoujdate",
   "AitDaoud",
   "Ajdir",
   "Akchour",
   "Akka",
   "Aklim",
   "Aknoul",
   "AlAroui",
   "AlHoceïma",
   "Alnif",
   "AmalouIghriben",
   "Amizmiz",
   "Anzi",
   "Aoufous",
   "Aoulouz",
   "Aourir",
   "Arazane",
   "Arbaoua",
   "Arfoud",
   "Assa",
   "Assahrij",
   "Assilah",
   "Awsard",
   "Azemmour",
   "Azilal",
   "Azrou",
   "AïnBniMathar",
   "AïnCheggag",
   "AïnDorij",
   "AïnErreggada",
   "AïnHarrouda",
   "AïnJemaa",
   "AïnKarma",
   "AïnLeuh",
   "AïtAttab",
   "AïtBaha",
   "AïtBoubidmane",
   "AïtHichem",
   "AïtIaâza",
   "AïtIshaq",
   "AïtMajden",
   "AïtMelloul",
   "AïtOurir",
   "AïtYalla",
   "BabBerred",
   "BabTaza",
   "Bejaâd",
   "BenAhmed",
   "BenGuerir",
   "BenSergao",
   "BenTaïeb",
   "BenYakhlef",
   "BeniAyat",
   "Benslimane",
   "Berkane",
   "Berrechid",
   "Bhalil",
   "Binelouidane",
   "Biougra",
   "BirJdid",
   "BniAnsar",
   "BniBouayach",
   "BniChiker",
   "BniDrar",
   "BniHadifa",
   "BniTadjite",
   "Bouanane",
   "Bouarfa",
   "Boudnib",
   "Boufakrane",
   "Bouguedra",
   "Bouhdila",
   "Bouizakarne",
   "Boujdour",
   "Boujniba",
   "Boulanouare",
   "Boulemane",
   "Boumalne-Dadès",
   "Boumia",
   "Bouskoura",
   "Bouznika",
   "Bradia",
   "Brikcha",
   "Bzou",
   "BéniMellal",
   "Casablanca",
   "Chefchaouen",
   "Chichaoua",
   "DarBniKarrich",
   "DarChaoui",
   "DarElKebdani",
   "DarGueddari",
   "DarOuladZidouh",
   "DcheiraElJihadia",
   "Debdou",
   "Demnate",
   "Deroua",
   "DouarKannine",
   "Dra'a",
   "Drargua",
   "Driouch",
   "Echemmaia",
   "ElAïounSidiMellouk",
   "ElBorouj",
   "ElGara",
   "ElGuerdane",
   "ElHajeb",
   "ElHanchane",
   "ElJadida",
   "ElKelaâdesSraghna",
   "ElKsiba",
   "ElMarsa",
   "ElMenzel",
   "ElOuatia",
   "Elkbab",
   "Er-Rich",
   "Errachidia",
   "Es-Semara",
   "Essaouira",
   "FamElHisn",
   "Farkhana",
   "Figuig",
   "Fnideq",
   "FoumJamaa",
   "FoumZguid",
   "FquihBenSalah",
   "Fraïta",
   "Fès",
   "Gardmit",
   "Ghafsai",
   "Ghmate",
   "Goulmima",
   "Gourrama",
   "Guelmim",
   "Guercif",
   "Gueznaia",
   "Guigou",
   "Guisser",
   "HadBouhssoussen",
   "HadKourt",
   "HajKaddour",
   "Harhoura",
   "HarteLyamine",
   "Hattane",
   "Hrara",
   "IdaOugnidif",
   "Ifrane",
   "Ifri",
   "Igdamen",
   "Ighiln'Oumgoun",
   "Ighoud",
   "Ighounane",
   "Ihddaden",
   "Imassine",
   "Imintanoute",
   "ImouzzerKandar",
   "ImouzzerMarmoucha",
   "Imzouren",
   "Inahnahen",
   "Inezgane",
   "Irherm",
   "Issaguen(Ketama)",
   "Itzer",
   "JamâatShaim",
   "Jaâdar",
   "Jebha",
   "Jerada",
   "Jorf",
   "JorfElMelha",
   "JorfLasfar",
   "Karia",
   "Karia(ElJadida)",
   "KariaBaMohamed",
   "KariatArekmane",
   "KasbaTadla",
   "Kassita",
   "Kattara",
   "KehfNsour",
   "Kelaat-M'Gouna",
   "Kerouna",
   "Kerrouchen",
   "KhemisZemamra",
   "Khenichet",
   "Khouribga",
   "KhémisSahel",
   "Khémisset",
   "Khénifra",
   "KsarElKébir",
   "Kénitra",
   "Laaounate",
   "Laayoune",
   "Lakhsas",
   "Lakhsass",
   "LallaMimouna",
   "LallaTakerkoust",
   "Larache",
   "Laâtamna",
   "Loudaya",
   "Loulad",
   "Lqliâa",
   "Lâattaouia",
   "M'diq",
   "M'haya",
   "M'rirt",
   "M'semrir",
   "Madagh",
   "Marrakech",
   "Martil",
   "Massa(Maroc)",
   "MechraBelKsiri",
   "Megousse",
   "Mehdia",
   "Meknès",
   "Midar",
   "Midelt",
   "Missour",
   "Mohammadia",
   "Moqrisset",
   "MoulayAbdallah",
   "MoulayAliCherif",
   "MoulayBouazza",
   "MoulayBousselham",
   "MoulayBrahim",
   "MoulayIdrissZerhoun",
   "MoulayYaâcoub",
   "Moussaoua",
   "MyAliCherif",
   "Mzouda",
   "Médiouna",
   "N'ZalatBniAmar",
   "Nador",
   "Naima",
   "Oualidia",
   "Ouaouizeght",
   "Ouaoumana",
   "Ouarzazate",
   "Ouazzane",
   "OuedAmlil",
   "OuedHeimer",
   "OuedIfrane",
   "OuedLaou",
   "OuedRmel",
   "OuedZem",
   "Oued-Eddahab",
   "Oujda",
   "OuladAbbou",
   "OuladAmrane",
   "OuladAyad",
   "OuladBerhil",
   "OuladFrej",
   "OuladGhadbane",
   "OuladH'RizSahel",
   "OuladM'Barek",
   "OuladM'rah",
   "OuladSaïd",
   "OuladSidiBenDaoud",
   "OuladTeïma",
   "OuladYaich",
   "OuladZbair",
   "OuledTayeb",
   "Oulmès",
   "Ounagha",
   "OutatElHaj",
   "PointCires",
   "Rabat",
   "RasElAïn",
   "RasElMa",
   "RibateElKheir",
   "Rissani",
   "Rommani",
   "SabaaAiyoun",
   "Safi",
   "Salé",
   "Sarghine",
   "Saïdia",
   "SebtElMaârif",
   "SebtGzoula",
   "SebtJahjouh",
   "Selouane",
   "Settat",
   "SidL'Mokhtar",
   "SidZouin",
   "SidiAbdallahGhiat",
   "SidiAddi",
   "SidiAhmed",
   "SidiAliBanHamdouche",
   "SidiAllalElBahraoui",
   "SidiAllalTazi",
   "SidiBennour",
   "SidiBouOthmane",
   "SidiBoubker",
   "SidiBouknadel",
   "SidiBouzid",
   "SidiIfni",
   "SidiJaber",
   "SidiKacem",
   "SidiLyamani",
   "SidiMohamedbenAbdallahel-Raisuni",
   "SidiRahhal",
   "SidiRahhalChataï",
   "SidiSlimane",
   "SidiSlimaneEchcharaa",
   "SidiSmaïl",
   "SidiTaibi",
   "SidiYahyaElGharb",
   "Skhinate",
   "Skhirate",
   "SkhourRehamna",
   "Skoura",
   "Smimou",
   "Soualem",
   "SoukElArbaa",
   "SoukSebtOuladNemma",
   "Stehat",
   "Séfrou",
   "Tabounte",
   "Tafajight",
   "Tafetachte",
   "Tafraout",
   "Taghjijt",
   "Taghzout",
   "Tagzen",
   "Tahannaout",
   "Tahla",
   "TalaTazegwaght",
   "Taliouine",
   "Talmest",
   "Talsint",
   "Tamallalt",
   "Tamanar",
   "Tamansourt",
   "Tamassint",
   "Tamegroute",
   "Tameslouht",
   "Tamesna",
   "Tamraght",
   "Tan-Tan",
   "Tanalt",
   "Tanger",
   "TanoumriteNkobZagora",
   "Taounate",
   "Taourirt",
   "Taourirtaitzaghar",
   "Tarfaya",
   "Targuist",
   "Taroudannt",
   "Tata",
   "Taza",
   "Taïnaste",
   "Temsia",
   "Tendrara",
   "TharEs-Souk",
   "Tichoute",
   "Tiddas",
   "Tiflet",
   "Tifnit",
   "Tighassaline",
   "Tighza",
   "Timahdite",
   "Tinejdad",
   "Tisgdal",
   "Tissa",
   "TitMellil",
   "Tizguite",
   "TiziOuasli",
   "Tiznit",
   "Tiztoutine",
   "Touarga",
   "Touima",
   "Touissit",
   "Toulal",
   "Toundoute",
   "Tounfite",
   "Témara",
   "Tétouan",
   "Youssoufia",
   "Zag",
   "Zagora",
   "Zaouiad'Ifrane",
   "ZaouïatCheikh",
   "Zaïda",
   "Zaïo",
   "Zeghanghane",
   "Zeubelemok",
   "Zinat",
];
